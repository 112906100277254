import { createSlice } from "@reduxjs/toolkit";

export const screenSizeSlice = createSlice({
  name: "screenSize",
  initialState: {
    size: window.innerWidth
  },
  reducers: {
    setSize: (state, { payload } ) => {
      state.size = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSize } = screenSizeSlice.actions