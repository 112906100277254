import styled from "styled-components";

export const LoadingContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.$type === "client" ? "#b4d2f0d3" : "rgba(0, 0, 0, 0.082)")};
  height: 100%;
  margin-left: 0.3rem;
  border-radius: 5px;
  span {
    background-color: #444444;
    color: ${(props) => props.$colorText || "#000000"};
    padding: ${props => {
    if(props.$size === 1) return "5px";
    if(props.$size === 2) return "6px";
    if(props.$size === 3) return "7px";
    return "5px";
    }};
    border-radius: 100%;
    margin: 5px;
    max-width: 70%;
    font-size: 0.9rem;
    transition: all ease 0.3s;
    animation: loading 2s infinite;
  }

  span:nth-child(1) {
    animation-delay: 0.1s;
  }
  span:nth-child(2) {
    animation-delay: 0.5s;
  }
  span:nth-child(3) {
    animation-delay: 1s;
  }

  @keyframes loading {
    0% {
      background-color: ${props => props.$colorMinAnimate};
      scale: 1;
    }

    50% {
      background-color: ${props => props.$colorMaxAnimate};
      scale: 1.2;
    }

    100% {
      background-color: ${props => props.$colorMinAnimate};
      scale: 1;
    }
  }
`;