import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  files_openai: [],
  videos: [],
};

export const docsGalery = createSlice({
  name: "docsGalery",

  initialState,
  reducers: {
    setFilesGalery: (state, { payload }) => {
      state.files_openai = payload;
    },
    setVideos: (state, { payload }) => {
      state.videos = payload;
    },
  },
});

export const { setFilesGalery, setVideos } = docsGalery.actions;
