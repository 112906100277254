import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
//services
import { GeneratingDefaultAssistants } from "./services/GeneratingDefaultAssistants";
//toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "./services/auth.service";
import VideoGallery from "./components/VideoGalery";
import Login from "./containers/Login";

//OneTraining
import MetricsTraining from "./containers/MetricsTraining";
import Profile from "./containers/Profile";
import Settings from "./containers/Settings";
import PlaygroundClient from "./containers/Playground";
import KnowledgeClient from "./containers/Knowledge";
import QuestionsAssistantClient from "./containers/QuestionsAssistant";

//axios
import axios from "axios";
// Inside One - P5
import ReportAgents from "./containers/ReportAgents";
import SingleEvaluationsClient from "./containers/SingleEvaluations";
// REDUX
import {
  setFilesOpenAI,
  setManualKnowledge,
} from "./store/reducers/slice/infoAssistant/infoAsssistant";
import { setFilesGalery } from "./store/reducers/slice/docsGalery/docsGalery";

import { useDispatch } from "react-redux";
import { setSize } from "./store/reducers/slice/screenSize";
import { setFineTuning } from "./store/reducers/slice/fineTuning/fineTuning";

import { setQuestionsHistory } from "./store/reducers/slice/questionsTraining";
import { setQuestions } from "./store/reducers/slice/questionsAssistant";

import { setData, setIsFetching } from "./store/reducers/slice/firestore";
//utils
import { dateFormater } from "./utils/dateFormater";
// firebase
import firebase from "../src/config/firebaseIO";

// dotenv
import dotenv from "dotenv";

import QuestionsPlaygroundClient from "./containers/QuestionsPlayground";
import FineTuningClient from "./containers/FineTuning";

dotenv.config(); // NO mover, TIENE que quedar ante-ultimo en los imports

// firestore
const db = firebase.firestore(); // NO mover, TIENE que quedar ultimo en los imports



const Main =  () => {
  // * redux - selectors
  const isAuth = useSelector((state) => state.user.isAuth);
  const loginUser = useSelector((state) => state.user.loginUser);
  const { name, description } = useSelector(
    (state) => state.user.companyInfo
  );
  
  

  //useState
  // Almacena la referencia a la suscripción en un estado local
  //const [unsubscribe, setUnsubscribe] = useState(null);

  const dispatch = useDispatch();
  const connStatus = () => {
    AuthService.connStatus();
  };

  // OJO que sin los [] es bucle infinito
  useEffect(() => {
    connStatus();
  }, []);


  const fetchCompleteData = async () => {
    // dispatch(setIsFetching(true));

    try {
      const companyRef = db.collection("companies").doc(loginUser.company);
      const unsubscribeCompany = companyRef.onSnapshot(async (doc) => {
        if (doc.exists) {
          const companyData = doc.data();
          const fineTuning = companyData.fine_tuning;
          const questions = companyData.questions;
          const manualKnowledge = companyData.manualKnowledge;
          const companyName = companyData.name;
          if (!companyData.assistants_id && companyData.name !== undefined) {
            await  GeneratingDefaultAssistants({companyName});
          }
          dispatch(setQuestions(questions));
          dispatch(setFineTuning(fineTuning));
          dispatch(setManualKnowledge(manualKnowledge));



          //!theReakMDFKDOUBLEDispatch
          dispatch(setFilesGalery(companyData.files_openai))
         
          dispatch(setFilesOpenAI(companyData.files_openai));
        } else {
          console.log("El documento no existe");
        }
      });
    } catch (err) {
      throw new Error(err.message);
    }
  };
  const fetchDataTraining = async () => {
    try {
      const collectionRef = db
        .collection("companies")
        .doc(loginUser.company)
        .collection("training")
        .orderBy("date", "desc")
        .onSnapshot((querySnapshot) => {
          const firebaseData = querySnapshot.docs.map((doc) => {
            const timestamp = doc.data().date;
            let date;
            if (timestamp) {
              date = new Date(
                timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
              ).toString();
            }
            return {
              ...doc.data(),
              date,
              dateFormated: dateFormater(new Date(date)),
              id: doc.id,
            };
          });
          dispatch(setData(firebaseData));
        });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAssistantHistory = async () => {
    try {
      const collectionRef = db
        .collection("companies")
        .doc(loginUser.company)
        .collection("assistantHistory")
        .where("infoUser.userId", "==", loginUser.userUID)
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => {
            const timestamp = doc.data().createdAt;
            let date;
            if (timestamp) {
              date = new Date(
                timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
              ).toString();
            }
            return {
              ...doc.data(),
              date,
              dateFormated: dateFormater(new Date(date)),
              id: doc.id,
            };
          });

          dispatch(setQuestionsHistory(data));
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isAuth) {
      fetchCompleteData();
      fetchDataTraining();
      fetchAssistantHistory();
    }
  }, [isAuth]);

  window.onresize = () => {
    dispatch(setSize(window.innerWidth));
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isAuth && isAuth === true ? (
        <Switch>
          <Route exact path="/" component={Login} />

          {/* ONE TRAINI */}
          {/* <Route exact path="/licenses" component={ReportsCollab} /> */}
          <Route exact path="/agents" component={ReportAgents} />
          <Route exact path="/metrics" component={MetricsTraining} />
          <Route
            exact
            path="/evaluations"
            component={SingleEvaluationsClient}
          />
          <Route
            exact
            path="/questions"
            component={QuestionsPlaygroundClient}
          />
          <Route exact path="/finetuning" component={FineTuningClient} />
          <Route exact path="/questions-assistant" component={QuestionsAssistantClient} />

          <Route exact path="/playground" component={PlaygroundClient} />
         {["Developer" ,"Supervisor" , "Administrator"].includes(loginUser?.rol) && <Route exact path="/knowledge" component={KnowledgeClient} />}
          <Route exact path="/learning" component={VideoGallery} />
          {/* GENERAL */}
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/settings" component={Settings} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" component={Login} />
        </Switch>
      )}
    </>
  );
};

export default Main;
