import { dateFormater } from "../dateLogic/dateFormater";
import { setTimeAgo } from "../dateLogic/setTimeAgo";

//De toda la informacion que existe, promedia cuantos indices de satisfaccion altos, medios y bajos hay. Si por parametro recibe una fecha, calcula el promedio (bajo, medio y alto) que hubo en el dia de esa fecha.
export const indexDataChart = (items, date, isWithinOneMonth = false) => {
  let filteredItems = items;
  const length = filteredItems?.length;

  if (items && isWithinOneMonth) {
    filteredItems = items?.filter((el) => {
      const itemDate = new Date(el.date);
      return setTimeAgo(itemDate, 30);
    });
  } else if (items && date) {
    const specificDate = new Date(date);

    filteredItems = items?.filter((el) => {
      const itemDate = new Date(el.date);

      return (
        itemDate.getFullYear() === specificDate.getFullYear() &&
        itemDate.getMonth() === specificDate.getMonth() &&
        itemDate.getDate() === specificDate.getDate()
      );
    });
  }

  const counts = {
    Alto: 0,
    Medio: 0,
    Bajo: 0,
  };

  filteredItems &&
    filteredItems.forEach((el) => {
      if (el.client?.satisfaction_index in counts) {
        counts[el.client?.satisfaction_index]++;
      }
    });

  const percentages = {
    Alto: (counts.Alto / length) * 100,
    Medio: (counts.Medio / length) * 100,
    Bajo: (counts.Bajo / length) * 100,
  };

  const data = {
    labels: filteredItems?.length
      ? ["0% - 39%", "40% - 69%", "70% - 100%"]
      : ["No hay interacciones"],
    datasets: [
      {
        data: filteredItems?.length
          ? [percentages.Bajo, percentages.Medio, percentages.Alto]
          : [100],
        backgroundColor: filteredItems?.length
          ? [
              "rgba(237,110,133,255)",
              "rgba(248,206,107,255)",
              "rgba(108,190,191,255)",
            ]
          : "lightgrey",
      },
    ],
  };

  return data;
};

export const indexDataCalificationChart = (items, language) => {
  let everyCalification =
    items
      ?.map((item) => {
        return item.analyze.calification;
      })
      .filter((n) => !isNaN(n)) || [];

  //BAJO DE 0 A 39
  //MEDIO DE 40 A 69
  //ALTO DE 70 A 100
  const isEnglish = language === "english";
  const languageConverterter = {
    High: isEnglish ? "High" : "Alto",
    Medium: isEnglish ? "Medium" : "Medio",
    Low: isEnglish ? "Low" : "Bajo",
  };

  function groupByLevel(percentage) {
    if (percentage >= 0 && percentage <= 39) {
      return languageConverterter.Low;
    } else if (percentage >= 40 && percentage <= 69) {
      return languageConverterter.Medium;
    } else if (percentage >= 70 && percentage <= 100) {
      return languageConverterter.High;
    } else {
      return "Out of range";
    }
  }

  const groups = {
    [languageConverterter.High]: 0,
    [languageConverterter.Medium]: 0,
    [languageConverterter.Low]: 0,
  };

  for (const value of everyCalification) {
    const level = groupByLevel(value);
    groups[level]++;
  }

  const dataChart = {
    labels: Object.keys(groups),
    datasets: [
      {
        data: Object.values(groups),
        backgroundColor: [
          "rgba(108,190,191,1)",
          "rgba(248,206,107,1)",
          "rgba(237,110,133,1)",
        ],
      },
    ],
  };
  return dataChart;
};

//La siguiente funcion sirve para saber cuantas llamadas hubo fuera de horario laboral, y cuantas hubo dentro de horario laboral.
export const technicalityDataChart = (items, language) => {
  let everyTechnicality =
    items
      ?.map((item) => {
        return item.analyze.technicality;
      })
      .filter((n) => !isNaN(n)) || [];

  //BAJO DE 0 A 39
  //MEDIO DE 40 A 69
  //ALTO DE 70 A 100
  const isEnglish = language === "english";
  const languageConverterter = {
    High: isEnglish ? "High" : "Alto",
    Medium: isEnglish ? "Medium" : "Medio",
    Low: isEnglish ? "Low" : "Bajo",
  };

  function groupByLevel(percentage) {
    if (percentage >= 0 && percentage <= 39) {
      return languageConverterter.Low;
    } else if (percentage >= 40 && percentage <= 69) {
      return languageConverterter.Medium;
    } else if (percentage >= 70 && percentage <= 100) {
      return languageConverterter.High;
    } else {
      return "Out of range";
    }
  }

  const groups = {
    [languageConverterter.High]: 0,
    [languageConverterter.Medium]: 0,
    [languageConverterter.Low]: 0,
  };

  for (const value of everyTechnicality) {
    const level = groupByLevel(value);
    groups[level]++;
  }

  const dataChart = {
    labels: Object.keys(groups),
    datasets: [
      {
        data: Object.values(groups),
        backgroundColor: [
          "rgba(108,190,191,1)",
          "rgba(248,206,107,1)",
          "rgba(237,110,133,1)",
        ],
      },
    ],
  };
  return dataChart;
};

export const timeDataChart = (items, language) => {
  let everyTime =
    items
      ?.map(({ responseTime }) => Math.floor(responseTime))
      .filter((n) => !isNaN(n)) || [];

  // rango de 0 a 40  (en verde) MEjor tiempo
  // rango de 40 a 80  (en amarillo) optimo
  // rango de 80 a 120  (en rojo) peor tiempo
  // rango de 120 a lo que sea  (en ROJO MAS FUERTE) pasado de tiempo

  // * Claudio ⭐⭐
  const isEnglish = language === "english";
  const languageConverterter = {
    Optimo: isEnglish ? "Optimal" : "Óptimo",
    Correct: isEnglish ? "Correct" : "Correcto",
    Exceeded: isEnglish ? "Exceeded" : "Excedido",
  };

  const groupByLevel = (percentage) =>
    percentage >= 0 && percentage <= 60
      ? languageConverterter.Optimo
      : percentage > 60 && percentage < 120
      ? languageConverterter.Correct
      : languageConverterter.Exceeded;

  const groups = {
    [languageConverterter.Optimo]: 0,
    [languageConverterter.Correct]: 0,
    [languageConverterter.Exceeded]: 0,
  };

  for (const n of everyTime) {
    const level = groupByLevel(n);
    groups[level]++;
  }

  // bajo rgba(108,190,191,1)
  // medio "rgba(248,206,107,1)",
  // alto rgba(108,190,191,1)

  const dataChart = {
    labels: Object.keys(groups),
    datasets: [
      {
        data: Object.values(groups),
        backgroundColor: [
          "rgba(108,190,191,1)",
          "rgba(248,206,107,1)",
          "rgba(237,110,133,1)",
        ],
      },
    ],
  };
  return dataChart;
};

//La siguiente funcion sirve para calcular el promedio de indice de satisfaccion por dia.
export const indexPerDayDataChart = (items) => {
  // Filtra las interacciones que están dentro del rango de fechas actual
  const interactionsThisWeek = items?.filter((interaction) => {
    const interactionDate = new Date(interaction?.date);
    return setTimeAgo(interactionDate, 7);
  });

  const averageRatingByDay = {};

  // Calcula el promedio de calificaciones por día
  interactionsThisWeek &&
    interactionsThisWeek.forEach((interaction) => {
      const interactionDate = new Date(interaction?.date);
      const dayKey = interactionDate.toLocaleDateString("es-ES");

      if (averageRatingByDay[dayKey]) {
        averageRatingByDay[dayKey].totalRating +=
          interaction.client?.rating || 0;
        averageRatingByDay[dayKey].totalInteractions++;
      } else {
        averageRatingByDay[dayKey] = {
          totalRating: interaction.client?.rating || 0,
          totalInteractions: 1,
        };
      }
    });

  // Calcula el promedio de calificación final por día
  Object.keys(averageRatingByDay).forEach((day) => {
    averageRatingByDay[day].averageRating =
      averageRatingByDay[day].totalRating /
      averageRatingByDay[day].totalInteractions;
  });

  // Ordena las claves (fechas) y crea las etiquetas y datos para el gráfico
  const sortedKeys = Object.keys(averageRatingByDay).sort((a, b) => {
    const dateA = new Date(a.split("/").reverse().join("/"));
    const dateB = new Date(b.split("/").reverse().join("/"));
    return dateA - dateB;
  });

  const labels = [];
  const data = [];
  sortedKeys.forEach((date) => {
    const ratingData = averageRatingByDay[date];
    labels.push(date);
    data.push(ratingData.averageRating.toFixed(2)); // Usa toFixed(2) para dos decimales
  });

  // Crea los datos del gráfico con las etiquetas y datos ordenados
  const chartData = {
    labels,
    datasets: [
      {
        label: "Promedio de rating por día (%)",
        data,
        backgroundColor: data.map((value) => {
          const numericValue = parseInt(value);
          return numericValue > 70
            ? "rgba(108,190,191,1)"
            : numericValue >= 40
            ? "rgba(248,206,107,1)"
            : "rgba(237,110,133,1)";
        }),
      },
    ],
  };

  // Retorna el objeto chartData
  return chartData;
};

export function weeklyAvarageRating(dbData , language) {
  const interactionsThisWeek = dbData?.filter((interaction) => {
    const interactionDate = new Date(interaction?.date);
    const dayOfWeek = interactionDate.getDay();
    return dayOfWeek >= 1 && dayOfWeek <= 5 && setTimeAgo(interactionDate, 6);
  });

  const evaluationsPerWeek = {};
  for (let index = 0; index < interactionsThisWeek?.length; index++) {
    const ratings = parseInt(interactionsThisWeek[index].analyze.calification);
    const dateFormated = interactionsThisWeek[index].dateFormated.split(" ")[0];
    if (!evaluationsPerWeek[dateFormated]) {
      evaluationsPerWeek[dateFormated] = { count: 0, quantity: 0 };
    }

    if (!isNaN(ratings)) {
      evaluationsPerWeek[dateFormated].count++;
      evaluationsPerWeek[dateFormated].quantity += ratings;
    }

    if (index === interactionsThisWeek?.length - 1) {
      const arrKeys = Object.keys(evaluationsPerWeek)
      for (let index = 0; index < arrKeys.length; index++) {
        const key = arrKeys[index];
        const quantity = evaluationsPerWeek[key].quantity
        const count =  evaluationsPerWeek[key].count
        evaluationsPerWeek[key].quantity = Math.round(quantity / count)
      }
     
    }
  }

  const labels = Object.keys(evaluationsPerWeek).reverse()
  const data = Object.values(evaluationsPerWeek).map(el => el.quantity).reverse()

  const chartData = {
    labels,
    datasets: [
      {
        label:
          language === "english"
            ? "Weekly evaluations"
            : "Evaluaciones semanales",
        data,
        backgroundColor: ["#ffb34a"],
      },
    ],
  };


  return chartData



}

//La siguiente funcion sirve para obtener la cantidad de interacciones semanales
export const interactionsDataChart = (items, language) => {
  const interactionsThisWeek = items?.filter((interaction) => {
    const interactionDate = new Date(interaction?.date);
    const dayOfWeek = interactionDate.getDay();
    return dayOfWeek >= 1 && dayOfWeek <= 5 && setTimeAgo(interactionDate, 6);
  });

  const interactionsByDay = {};

  interactionsThisWeek &&
    interactionsThisWeek.forEach((interaction) => {
      const interactionDate = new Date(interaction?.date);
      const dayKey = interactionDate.toLocaleDateString("es-ES");

      if (interactionsByDay[dayKey]) {
        interactionsByDay[dayKey]++;
      } else {
        interactionsByDay[dayKey] = 1;
      }
    });

  // [["20/09/2023", 2], ["21/09/2023", 45]] como llega
  const datesAndInteractions = Object.entries(interactionsByDay).sort(
    (a, b) => {
      const fechaA = new Date(a[0].split("/").reverse().join("-"));
      const fechaB = new Date(b[0].split("/").reverse().join("-"));
      return fechaA - fechaB;
    }
  );

  let labels = [];
  let data = [];

  datesAndInteractions &&
    datesAndInteractions.forEach(([date, interactions]) => {
      labels.push(date);
      data.push(interactions);
    });

  const chartData = {
    labels,
    datasets: [
      {
        label:
          language === "english"
            ? "Weekly Interactions"
            : "Interacciónes semanales",
        data,
        backgroundColor: ["#ffb34a"],
      },
    ],
  };

  return chartData;
};
