import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

import UserModal from "../commons/singleView/UserModal";
import { TrashIcon } from "../commons/icons/TrashIcon";
import { EditIcon } from "../commons/icons/Editicon";
import { ChevronDown } from "../commons/icons/ChevronDown";
import PopUpRecover from "./PopUpRecover";
//styled components
import styled from "styled-components";
//components
import { LoadingContainer } from "../commons/loading/LoadingContainer";
import { LoadingSpinner } from "../commons/loading/LoadingSpinner";

export default ({
  screenSize,
  darkModeState,
  language,
  loginUser,
  popUp,
  handlePopUp,
  handleRecover,
  handleRecoverMail,
  handleDeleteUser,
  recoverMessage,
  usersCompany,
  setReloadUsers,
  loadingDeleteUser,
}) => {
  const [modal, setModal] = useState(false);
  const [sureToDeleteUserModal, setSureToDeleteUserModal] = useState({
    state: false,
    userID: null,
    adminID: null,
  });

  const [modalSwitchRol, setModalSwitchRol] = useState(null);

  const [filtrarInput, setFiltrarInput] = useState("");
  const [roleFilter, setFiltrarRol] = useState("");

  const handleSearchChange = (e) => {
    setFiltrarInput(e.target.value);
  };

  const handleRoleChange = (e) => {
    setFiltrarRol(e.target.value);
  };

  const handleClearFilters = () => {
    setFiltrarInput("");
    setFiltrarRol("");
  };

  const filteredUsers = usersCompany?.filter((user) => {
    const filtrarInputLower = filtrarInput.toLowerCase();
    const matchesSearch =
      user.firstName.toLowerCase().includes(filtrarInputLower) ||
      user.lastName.toLowerCase().includes(filtrarInputLower) ||
      user.mail.toLowerCase().includes(filtrarInputLower);
    const matchesRole =
      roleFilter === "" || user.rol.toLowerCase() === roleFilter.toLowerCase();
    return matchesSearch && matchesRole;
  });

  const styles = {
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
      padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
      height: "100%",
      overflow: `auto`,
    },

    navbar: {
      display: "flex",
      // flex: 1.5,
      margin: `${screenSize > 800 ? "0px 20px 20px 20px" : "0px 0px 20px 0px"}`,
      marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
    },
    textsNavbar: {
      display: "flex",
      flex: 8,
      flexDirection: "column",
    },
    titleNavbar: {
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    textToogle: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: "12px",
      backgroundColor: `transparent`,
    },
    textUser: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: 0,
      backgroundColor: `transparent`,
    },
    buttonNew: {
      textDecoration: "none",
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      margin: "20px",
      // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
      width: `${screenSize > 800 ? "180px" : <></>}`,
    },
    user: {
      display: "flex",
      // flex: 1,
      flexDirection: "column",
      alignItems: "flex-start",
      margin: `${screenSize > 800 ? "0px 20px 20px 20px" : "0px 0px 20px 0px"}`,
    },
    singleLine: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    singleLineMargin: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "40px 0px" : "10px"}`,
    },
    type: {
      display: "flex",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "18px",
      fontFamily: "InterBold",
      alignSelf: "center",
    },
    value: {
      display: "flex",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: 0,
      fontSize: "18px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      border: "none",
      outline: "none",
      backgroundColor: `transparent`,
    },
    loader: {
      display: "flex",
      alignSelf: "center",
    },
    titleActivity: {
      display: "flex",
      alignItems: "center",
      fontSize: "24px",
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(228, 12%, 44%)"}`,
      margin: `${screenSize > 800 ? "20px 0px" : "0px"}`,
    },
    buttonAdd: {
      backgroundColor: "#4dc2f1",
      fontSize: "16px",
      fontFamily: "InterRegular",
      borderRadius: "5px",
      color: "#fff",
      padding: "10px",
      border: "0px",
      cursor: "pointer",
      height: "2.8rem",
    },
    styleIcons: {
      marginRight: 7,
      maxWidth: 26,
      maxHeight: 30,
      minWidth: 26,
      minHeight: 30,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 0px",

      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
    },
    userInfo: {
      display: "flex",
      alignItems: "center",
      width: "100%",

      paddingLeft: 10,
      justifyContent: "space-between",
    },
    iconContainer: {
      display: "flex",
      gap: "10px",
    },
    containerList: {
      display: "flex",
      alignItems: "center",
    },
    inputStyles: {
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontFamily: "InterRegular",
      backgroundColor: darkModeState ? "hsl(228, 34%, 20%)" : "white",
      color: darkModeState ? "white" : "black",
      flex: 1,
    },
    select: {
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontFamily: "InterRegular",
      backgroundColor: darkModeState ? "hsl(228, 34%, 20%)" : "white",
      color: darkModeState ? "white" : "black",
    },
  };
console.log(loginUser.rol)
  return (
    <>
      <div style={styles.backColor} />
      <div style={styles.container}>
        <div style={styles.navbar}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>
              {language === "english" ? "My Profile" : "Mi Perfil"} 😃
            </h3>
            <h3 style={styles.subtitleNavbar}>
              {language === "english"
                ? "All your profile."
                : "Todo tu perfil y configuraciones."}
            </h3>
          </div>
        </div>
        <div style={styles.user}>
          {popUp === true ? (
            <PopUpRecover
              screenSize={screenSize}
              darkModeState={darkModeState}
              language={language}
              handlePopUp={handlePopUp}
              handleRecover={handleRecover}
              handleRecoverMail={handleRecoverMail}
              recoverMessage={recoverMessage}
            />
          ) : (
            <></>
          )}
          {loginUser ? (
            <>
              <div
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={styles.singleLine}
              >
                <div style={styles.type}>
                  {language === "english" ? "Name" : "Nombre"}
                </div>
                <div style={styles.value}>
                  {loginUser.firstName} {loginUser.lastName}
                </div>
              </div>
              <div
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={styles.singleLine}
              >
                <div style={styles.type}>Rol</div>
                <div style={styles.value}>{loginUser.rol}</div>
              </div>
              <div
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={styles.singleLine}
              >
                <div style={styles.type}>
                  {language === "english" ? "Mail" : "Correo electrónico"}
                </div>
                <div style={styles.value}>{loginUser.mail}</div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {loginUser.rol === "Developer" && (
          <div style={{ ...styles.navbar, marginTop: "20px" }}>
            <div style={styles.textsNavbar}>
              <h3 style={styles.titleNavbar}>
                {language === "english"
                  ? "Add and remove users"
                  : "Agregar y quitar usuarios"}{" "}
                😎
              </h3>
              <h3 style={styles.subtitleNavbar}>
                {language === "english"
                  ? "You can add, edit and remove users"
                  : "Podes agregar, editar y quitar usuarios"}
              </h3>
            </div>
          </div>
        )}

        {loginUser.rol === "Developer" && (
          <>
            <div style={{ ...styles.user, marginTop: "20px" }}>
              <div style={{ display: "flex" }}>
                <button
                  style={{ ...styles.buttonAdd }}
                  onClick={() => setModal(true)}
                >
                  {language === "english" ? "Add user" : "Agregar usuario"}
                </button>
              </div>
            </div>

            <div>
              <h3 style={{ ...styles.subtitleNavbar, marginLeft: "20px" }}>
                {language === "english" ? "User List" : "Listado de Usuarios"}
              </h3>
            </div>
          </>
        )}

        {/* FILTROS */}
        {loginUser.rol === "Developer" && (
          <div
            style={{
              display: "flex",
              marginLeft: "20px",
              marginTop: "15px",
              marginBottom: "15px",
              paddingRight: "1rem",
            }}
          >
            <input
              type="text"
              placeholder={
                language === "english"
                  ? "Search by name, email..."
                  : "Buscar por nombre, correo..."
              }
              value={filtrarInput}
              onChange={handleSearchChange}
              style={{
                ...styles.inputStyles,
                padding: "5px",
                marginRight: "10px",
              }}
            />
            <select
              value={roleFilter}
              onChange={handleRoleChange}
              style={{ ...styles.select, padding: "5px", marginRight: "10px" }}
            >
              <option value="">
                {language === "english" ? "All roles" : "Todos los roles"}
              </option>
              <option value="Agent">
                {language === "english" ? "Agent" : "Agente"}
              </option>
              <option value="Supervisor">
                {language === "english" ? "Supervisor" : "Supervisor"}
              </option>
              <option value="Administrator">
                {language === "english" ? "Administrator" : "Administrador"}
              </option>
            </select>
            <button
              onClick={handleClearFilters}
              style={{ ...styles.buttonAdd }}
            >
              {language === "english" ? "Clear filters" : "Limpiar filtros"}
            </button>
          </div>
        )}
        {/* FILTROS FIN */}

        {loginUser.rol === "Developer" && (
          <ul style={{ margin: 0, padding: "0 20px" }}>
            {filteredUsers?.map((user, i) => {
              const isPar = i % 2 === 0;

              return (
                <User
                  modalSwitchRol={{ modalSwitchRol, setModalSwitchRol }}
                  key={user.uid}
                  user={user}
                  loginUser={loginUser}
                  styles={styles}
                  setSureToDeleteUserModal={setSureToDeleteUserModal}
                  language={language}
                  darkModeState={darkModeState}
                  isPar={isPar}
                />
              );
            })}
          </ul>
        )}
      </div>
      <>
        {loginUser.rol === "Developer" && (
          <>
            {sureToDeleteUserModal.state && (
              <ModalUserDelete
                handleDeleteUser={handleDeleteUser}
                language={language}
                sureToDeleteUserModal={sureToDeleteUserModal}
                darkModeState={darkModeState}
                setSureToDeleteUserModal={setSureToDeleteUserModal}
                loadingDeleteUser={loadingDeleteUser}
              />
            )}
            <UserModal
              setReloadUsers={setReloadUsers}
              isOpen={modal}
              loginUser={loginUser}
              handleClose={() => setModal(false)}
              darkModeState={darkModeState}
            />
          </>
        )}
      </>
    </>
  );
};

function User({
  user,
  loginUser,
  styles,
  language,
  setSureToDeleteUserModal,
  isPar,
  darkModeState,
  modalSwitchRol,
}) {
  const [userRol, setUserRol] = useState(user.rol);
  const [openSelectNewRol, setOpenSelectNewRol] = useState(false);
  const [rolesToChange, setRolesToChange] = useState([]);
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const rolSwitch = {
    Administrator: language === "english" ? "Administrator" : "Agente",
    Supervisor: language === "english" ? "Supervisor" : "Supervisor",
    Agent: language === "english" ? "Agent" : "Agente",
  };

  const handleOpenSelectNewRol = () => {
    modalSwitchRol.setModalSwitchRol(user.uid);
    setOpenSelectNewRol((prev) => !prev);
  };

  useEffect(() => {
    if (modalSwitchRol.modalSwitchRol !== user.uid) {
      setOpenSelectNewRol(false);
    }
  }, [modalSwitchRol.modalSwitchRol]);

  
  const handleSelectNewRol = async (newRol) => {
    setLoadingSwitch(true);
    await axios.post(
      `https://changeuserrol-cfcs6xjhea-uc.a.run.app/?adminID=${loginUser.userUID}`,
      {
        newRol,
        userID: user.uid,
      }
    );
    setLoadingSwitch(false);

    setUserRol(newRol);
  };

  useEffect(() => {
    setRolesToChange(Object.keys(rolSwitch).filter((r) => r !== userRol));
  }, [userRol]);

  return (
    <li
      style={{
        ...styles.listItem,
        backgroundColor: isPar
          ? darkModeState
            ? "rgba(255,255,255,0.1)"
            : "#f2f2f2"
          : "transparent",
      }}
    >
      <div style={{ ...styles.userInfo, ...styles.containerList }}>
        <p
          style={{
            color: darkModeState ? "#f2f2f2" : "#000",
            margin: 0,
            padding: 0,
            width: "calc(50% / 3)",
          }}
        >
          {user.firstName + " " + user.lastName}
        </p>

        <p
          style={{
            color: darkModeState ? "#f2f2f2" : "#000",
            margin: 0,
            padding: 0,
            width: "calc(55% / 3)",
          }}
        >
          {user.mail}
        </p>

        <SwitchRolContainer
          onClick={handleOpenSelectNewRol}
          className={openSelectNewRol ? "clickedSwitchRol" : ""}
        >
          <span className="ChevronDown">
            <ChevronDown styles={{ fill: "white" }} />
          </span>
          <p
            style={{
              color: darkModeState ? "#f2f2f2" : "#000",
              margin: 0,
              padding: 0,
              marginRight: "20px",
              width: "10rem",
              textAlign: "end",
            }}
          >
            {loadingSwitch ? (
              <LoadingContainer
                // $size={styles.sizeLoadingChat}
                $colorMaxAnimate={darkModeState ? "#f2f2f2" : "#000"}
                $colorMinAnimate={darkModeState ? "#f2f2f2" : "#000"}
                $colorText={"#c33030"}
                $type="bot"
              >
                <span className="loadingItems"></span>
                <span className="loadingItems"></span>
                <span className="loadingItems"></span>
              </LoadingContainer>
            ) : (
              userRol
            )}
          </p>
          {openSelectNewRol && (
            <SwitchSelectRoles darkModeState={darkModeState}>
              {rolesToChange.map((r, i) => {
                return (
                  <p onClick={() => handleSelectNewRol(r)} key={i}>
                    {r}
                  </p>
                );
              })}
            </SwitchSelectRoles>
          )}
        </SwitchRolContainer>
        {/* <div style={{marginLeft: "auto", marginRight: "20px"} }> </div> */}
      </div>
      <div style={{ ...styles.iconContainer }}>
        {/* <span>
          <EditIcon />
        </span>  */}
        <span
          onClick={() =>
            setSureToDeleteUserModal({
              state: true,
              userID: user.uid,
              adminID: loginUser.userUID,
            })
          }
        >
          <TrashIcon />
        </span>
      </div>
    </li>
  );
}

const SwitchRolContainer = styled.div`
  width: 12rem;
  height: 100%;
  position: relative;
  border-radius: 0.5rem;
  transition: ease all 0.3s;
  display: flex;
  justify-content: space-between;

  .ChevronDown {
    opacity: 0;
  }
  &:hover {
    background-color: ${(props) =>
      props.darkModeState ? "#f2f2f225" : "rgb(77, 194, 241)"};
    cursor: pointer;
    .ChevronDown {
      opacity: 1;
    }
  }

  &.clickedSwitchRol {
    background-color: ${(props) =>
      props.darkModeState ? "#f2f2f225" : "rgb(77, 194, 241)"};

    .ChevronDown {
      opacity: 1;
    }
  }
  user-select: none;
`;

const SwitchSelectRoles = styled.div`
  width: 100%;
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: end;
  box-shadow: 2px 3px 10px #000000b0;
  border-radius: 0 0 0.5rem 0.5rem;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  user-select: none;

  padding: 0.5rem 0 0 1rem;
  p {
    padding-right: 0.5rem;
    border-radius: 0.5rem;
    color: ${(props) => (props.darkModeState ? "#f2f2f2" : "#000")};
    width: 90%;
    transition: ease all 0.3s;
    &:hover {
      background-color: ${(props) =>
        props.darkModeState ? "#f2f2f225" : "#4dc2f1"};
    }
  }
  background-color: ${(props) =>
    props.darkModeState
      ? "hsl(230, 16.666666666666664%, 14.117647058823529%)"
      : "#f2f2f2 "};
`;

const ModalUserDelete = ({
  handleDeleteUser,
  setSureToDeleteUserModal,
  sureToDeleteUserModal,
  language,
  darkModeState,
  loadingDeleteUser,
}) => {
  const resetState = { state: false, userID: null, adminID: null };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: "999",
        display: "grid",
        placeContent: "center",
      }}
    >
      <div
        style={{
          borderRadius: "1rem",
          display: "grid",
          placeContent: "center",
          backgroundColor: darkModeState ? "hsl(230, 17%, 14%)" : "#f2f2f2",
          width: "30rem",
          height: "15rem",
          boxShadow: "2px 3px 10px black",
        }}
      >
        <span
          style={{
            color: darkModeState ? "rgb(255, 255, 255)" : "rgb(99, 104, 126)",
          }}
        >
          {language === "english"
            ? "Are you sure you want to delete this user?"
            : "¿Seguro de que quieres eliminar este usuario?"}
        </span>
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            gap: 10,
            marginTop: "30px",
          }}
        >
          {loadingDeleteUser ? (
            <LoadingSpinner />
          ) : (
            <>
              <button
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleDeleteUser({
                    userID: sureToDeleteUserModal.userID,
                    adminID: sureToDeleteUserModal.adminID,
                  });
                  setSureToDeleteUserModal(resetState);
                }}
                className="defaultButton"
                style={{ padding: "5px 20px", backgroundColor: "red" }}
              >
                Sí
              </button>
              <button
                className="defaultButton"
                style={{
                  padding: "5px 20px",
                  backgroundColor: "transparent",
                  color: darkModeState
                    ? "hsl(228, 34%, 66%)"
                    : "hsl(228, 12%, 44%)",
                  border: darkModeState
                    ? "1px solid hsl(228, 34%, 66%)"
                    : "1px solid hsl(228, 12%, 44%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSureToDeleteUserModal(resetState);
                }}
              >
                No
              </button>{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
