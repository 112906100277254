import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCTIr-r6BwpmJVU_KGEyyyP2FmyspTPS8c",
    authDomain: "insideone-onetraining.firebaseapp.com",
    projectId: "insideone-onetraining",
    storageBucket: "insideone-onetraining.appspot.com",
    messagingSenderId: "680394526226",
    appId: "1:680394526226:web:264b1692fbc0f0e97c12cb",
    measurementId: "G-SKD32K2VWZ"
  };

const app_one = firebase.initializeApp(firebaseConfig, 'appOne');

export default app_one;




