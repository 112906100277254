//La siguiente funcion sirve para saber que siempre tome las interacciones de esta semana.

export const setTimeAgo = (docDate, days) => {
  const currentDate = new Date();
  const isWithinDays = new Date(currentDate);
  isWithinDays.setDate(currentDate.getDate() - days);
  isWithinDays.setHours(0, 0, 0, 0);

  return docDate > isWithinDays;
};

