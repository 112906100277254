import React from "react";
import Confetti from "react-confetti";
import PopUpStatus from "./PopUpStatus";
import PopUpFeatures from "./PopUpFeatures";
import PopUpClose from "./PopUpClose";
import PopUpAssign from "./PopUpAssign";
import { dateFormater } from "../utils/dateLogic/dateFormater";
import cross from "../assets/icons/cross.svg";

// messageToSend, handleSubmit, playgroundState, handleChangeText, handleReset
export default ({
  screenSize,
  loginUser,
  messageToSend,

  handleReset,
  handleChangeText,
  darkModeState,
  handleReady,
  language,
  handleSubmit,
  handleStatus,
  chartState,
  status,
  messagesSelectedRedux,
  handleClose,
  popUpFeatures,
  handlePopUpFeatures,
  handlePopUpClose,
  popUpClose,
  popUpAssign,
  handlePopUpAssign,
  handleAssign,
  handleInitTest,
  playgroundTrainingState,
  handleInitTestRealAgent,
  companyName,
  testRealAgentState
}) => {
  const styles = {
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
      margin: 0,
      height: "100%",
    },
    navbar: {
      display: "flex",
      flex: 1.5,

      marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
    },
    textsNavbar: {
      display: "flex",
      flex: 8,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    titleNavbar: {
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      border: "none",
      outline: "none",
      padding: 0,
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    textToogle: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: "12px",
      backgroundColor: `transparent`,
    },
    indicators: {
      display: "flex",
      flex: 11,
      flexDirection: "column",
    },
    containerNoReady: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      height: "80%",
    },
    notReady: {
      display: "flex",
      alignSelf: "center",
      marginTop: "30px",
      fontSize: "20px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
    noChats: {
      display: "flex",
      alignSelf: "center",
      fontSize: "16px",
      fontFamily: "InterRegular",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
    formButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#4DC2F1",
      borderRadius: 25,
      border: "none",
      padding: "12px 32px",
      margin: "30px 0px 0px",
      color: "#fff",
      outline: 0,
    },
    chatContainer: {
      //backgroundColor: "green",
      height: "80vh",
      borderRadius: "8px",
      display: "flex",
      // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
    },
    chatSessions: {
      flex: 4,
      display: "flex",
      padding: "10px",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      //   backgroundColor: `green`,
      borderRadius: "10px",
      flexDirection: "column",
      //     borderRight: "solid 1px rgba(90,90,90,0.1)",
      // margin: '0px 0px 0px 10px',
      boxShadow: `${
        darkModeState
          ? "0 3px 16px 0 hsl(228, 28%, 11%)"
          : "0 3px 16px 0 rgba(90, 90, 90, 0.2)"
      }`,
      //  overflow: "auto",
    },
    chatSessionsTitle: {
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: "10px 10px 10px 20px",
    },
    chatNoSessions: {
      flex: 4,
      display: "flex",
      justifyContent: "center",
      padding: "10px",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      borderRadius: "10px",
      flexDirection: "column",
      boxShadow: `${
        darkModeState
          ? "0 3px 16px 0 hsl(228, 28%, 11%)"
          : "0 3px 16px 0 rgba(90, 90, 90, 0.2)"
      }`,
    },
    chatSessionPadding2: {
      display: "flex",
      flex: 2,
      padding: "0px 0px 10px 0px",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    chatSessionPadding3: {
      display: "flex",
      flex: 1,
      padding: "0px 0px 10px 0px",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    chatSessionPadding4: {
      display: "flex",
      flex: 4,
      padding: "0px",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      overflow: "auto",
    },
    chatSession2: {
      display: "flex",
      flex: 1,
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      borderRadius: "8px",
      flexDirection: "row",
      border: "none",
      //    height: "18%"
      // padding: '6px 0px'
    },
    chatSession3: {
      display: "flex",
      flex: 1,
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      borderRadius: "8px",
      flexDirection: "row",
      border: "none",
      //    height: "18%"
      // padding: '6px 0px'
    },
    chatSession4: {
      display: "flex",
      flex: 1,
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      borderRadius: "8px",
      flexDirection: "row",
      border: "none",
      // overflow: "auto",
      //        height: "100%"
      // padding: '6px 0px'
    },
    chatChannel: {
      display: "flex",
      padding: "0 2rem",
      alignItems: "center",
      justifyContent: "center",
      // height: '100%',
    },
    chatChannelImage: {
      display: "flex",
      alignSelf: "center",
      height: 40,
    },
    chatInformation: {
      display: "flex",
      flex: 3,
      alignItems: "flex-start",
      // justifyContent: 'center',
      flexDirection: "column",
      overflow: "auto",
    },
    chatSessionName: {
      margin: "5% 10px 0px 10px",
      display: "flex",
      fontSize: 14,
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
    chatSessionTextDiv: {
      display: "flex",
      //    alignSelf: 'flex-start',
      alignItems: "flex-end",
      justifyContent: "flex-end",
      height: "100%",
      //      overflow: "auto",
    },
    chatSessionTextDiv2: {
      display: "flex",
      //    alignSelf: 'flex-start',
      alignItems: "flex-end",
      justifyContent: "flex-end",
      //   height: '100%',
      //      overflow: "auto",
    },
    chatSessionText: {
      margin: "10px",
      display: "flex",
      alignSelf: "center",
      fontSize: "1.2rem",
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      whiteSpace: "initial",
      textAlign: "start",
      //    overflow: "auto",
      //lineBreak: 'anywhere'
    },
    chatSessionTextParagraph: {
      margin: "10px",
      display: "flex",
      alignSelf: "center",
      fontSize: 14,
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      whiteSpace: "initial",
      textAlign: "start",
      //     overflow: "auto",
      // lineBreak: 'anywhere'
    },
    chatReceived: {
      display: "flex",
      flexDirection: "column",
      margin: "0px 10px",
      padding: "15px 20px",
      maxWidth: "60%",
      alignSelf: "flex-start",
      alignItems: "flex-end",
      justifyContent: "center",
      backgroundColor: "#4dc2f1",
      color: "#fff",
      //boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
      borderRadius: "10px 10px 10px 0px",
      fontFamily: "InterRegular",
    },
    hourOfChatReceived: {
      display: "flex",
      margin: "8px 20px 10px 20px",
      alignSelf: "flex-start",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterRegular",
      fontSize: 12,
    },
    chatSent: {
      display: "flex",
      flexDirection: "column",
      padding: "15px 20px",
      maxWidth: "60%",
      margin: "10px 15px",
      alignSelf: "flex-end",
      alignItems: "flex-end",
      justifyContent: "center",
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(228, 12%, 44%)"}`,

      //boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
      borderRadius: "10px 10px 0px",
      fontFamily: "InterRegular",
    },
    hourOfChatSent: {
      display: "flex",
      margin: "8px 20px 10px",
      alignSelf: "flex-end",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterRegular",
      fontSize: 12,
    },
    chatMessages: {
      flex: 6,
      display: "flex",
      // backgroundColor: "blue",
      borderRadius: "10px",
      flexDirection: "column",
      height: "100%",
      margin: "0px 20px 0px 0px",
      boxShadow: `${
        darkModeState
          ? "0 3px 16px 0 hsl(228, 28%, 11%)"
          : "0 3px 16px 0 rgba(90, 90, 90, 0.2)"
      }`,
    },
    chatAll: {
      flex: 11,
      display: "flex",
      flexDirection: "column",
      height: "50%",
    },
    chatNoMessagesAll: {
      flex: 11,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      borderRadius: "0 10px 10px 0",
    },
    chatMessagesActions: {
      flex: 1,
      //  height: '150px',
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      // backgroundColor: 'white',
      // boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
      borderRadius: "8px",
      padding: "0px 20px",
      margin: "10px 10px 10px 10px",
    },
    singleMessages: {
      display: "flex",
      flex: 11,
      overflow: "auto",
      flexDirection: "column",
    },
    userInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    chatChannelSingle: {
      display: "flex",
      // flex: 1,
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    chatChannelImageSingle: {
      display: "flex",
      alignSelf: "center",
      height: 20,
    },

    nameUser: {
      color: `${darkModeState ? "#fff" : "hsl(228, 12%, 44%)"}`,
      margin: "0px 10px",
    },
    buttonActionsGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonActions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      border: "none",
      color: "#fff",
      outline: 0,
      padding: "0px 10px",
    },
    iconActions: {
      width: "20px",
      height: "20px",
      color: `${darkModeState ? "#fff" : "hsl(228, 12%, 44%)"}`,
      margin: "0px 10px 0px 0px",
    },
    iconAnalyticsDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "45px",
      height: "45px",
      backgroundColor: "#4dc2f1",
      borderRadius: "50px",
      padding: "13px",
    },
    iconAnalytics: {
      display: "flex",
      flex: 1,
      alignSelf: "center",
      color: `#fff`,
      margin: "0px",
    },
    iconAnalyticsDiv2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "25px",
      height: "25px",
      backgroundColor: "#4dc2f1",
      borderRadius: "50px",
      padding: "6px",
    },
    iconAnalytics2: {
      display: "flex",
      flex: 1,
      alignSelf: "center",
      color: `#fff`,
      margin: "0px",
    },
    textActions: {
      color: "#fff",
      margin: "0px",
      fontSize: "12px",
      fontFamily: "InterRegular",
      margin: "0px",
      color: `${darkModeState ? "#fff" : "hsl(228, 12%, 44%)"}`,
    },
    textBoxChat: {
      flex: 1,
      display: "flex",
      borderRadius: "0px 10px 10px 10px",
      borderTop: `${
        darkModeState
          ? "solid 1px rgba(90,90,90,0.3)"
          : "solid 0.2px rgb(240,240,240)"
      }`,
      //  flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    inputChat: {
      display: "flex",
      flex: 10,
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      border: "0px",
      padding: "15px 20px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterRegular",
      fontSize: "16px",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    textChat: {
      margin: "15px",
      fontSize: "16px",
      color: "black",
    },
    button: {
      display: "flex",
      flex: 2,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      backgroundColor:
        playgroundTrainingState.isLoading ||
        playgroundTrainingState.conversation?.length > 2
          ? "gray"
          : "#4dc2f1",
      height: "100%",
      border: "0px",
      color: "#fff",
      outline: 0,
      fontSize: "16px",
      fontFamily: "InterRegular",
    },
    buttonTest: {
      backgroundColor: "#4dc2f1",
      fontSize: "16px",
      fontFamily: "InterRegular",
      borderRadius: "5px",
      color: "#fff",
      marginTop: "20px",
      padding: "10px",
      border: "0px",
    },
    textButton: {
      fontSize: "16px",
      color: "black",
    },
    counter: { textAlign: "center" },
    textCounter: {
      fontSize: "100px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
    },
    buttonFloat: {
      position: "absolute",
      bottom: 0,
      left: 250,
      display: "flex",
      justifyContent: "flex-end",
      height: "280px",
    },
    custom: {
      color: "rgb(77, 194, 241)",
    },
    loadingsBox: {
      display: "flex",
      marginLeft: 5,
      gap: 20,
      height: 100,
    },
  };
  let isOurCompanyName =
    companyName.toLowerCase() === "insideone" ||
    companyName.toLowerCase() === "nubemax";

  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  if (`${month}`.length === 1) {
    month = "0" + month;
  }
  let date = new Date().getDate();
  if (`${date}`.length === 1) {
    date = "0" + date;
  }
  let dateTotal = date + " / " + month + " / " + year;

  return (
    <>
      {status === "ready" ? (
        <Confetti
          numberOfPieces={200}
          gravity={0.1}
          recycle={false}
          width={window.screen.width - 50}
          height={window.screen.height - 50}
        />
      ) : (
        <></>
      )}
      <div style={styles.backColor} />
      <div style={styles.container}>
        <div style={styles.navbar}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>
              {language === "english" ? (
                <>
                  Mode <span style={{ color: "#4dc2f1" }}>Playground</span>
                </>
              ) : (
                <>
                  Modo <span style={{ color: "#4dc2f1" }}>Playground</span>
                </>
              )}{" "}
              😎
            </h3>
            <h3 style={styles.subtitleNavbar}>
              {language === "english"
                ? "Use this site to do the tests."
                : "Utiliza este sitio para hacer pruebas."}
            </h3>
          </div>
          {/* <div className={darkModeState ? ("textDarkToogle") : ("textLightToogle")} style={styles.toogleNavbar}>
                        {darkModeState === true ? (<button style={styles.textToogle} onClick={handleDarkMode}><>{language === 'english' ? ('Light Mode') : ('Tema Claro')}</></button>) : (<button style={styles.textToogle} onClick={handleDarkMode}>{language === 'english' ? ('Dark Mode') : ('Tema Oscuro')}</button>)}
                    </div> */}
        </div>
        <div style={styles.indicators}>
          {chartState === true ? (
            <PopUpStatus
              darkModeState={darkModeState}
              screenSize={screenSize}
              handleReady={handleReady}
              handleStatus={handleStatus}
            />
          ) : (
            <></>
          )}
          {popUpFeatures === true ? (
            <PopUpFeatures
              screenSize={screenSize}
              darkModeState={darkModeState}
              language={language}
              handlePopUpFeatures={handlePopUpFeatures}
            />
          ) : (
            <></>
          )}
          {popUpClose === true ? (
            <PopUpClose
              screenSize={screenSize}
              darkModeState={darkModeState}
              language={language}
              handlePopUpClose={handlePopUpClose}
              handleClose={handleClose}
            />
          ) : (
            <></>
          )}
          {popUpAssign === true ? (
            <PopUpAssign
              screenSize={screenSize}
              darkModeState={darkModeState}
              language={language}
              handlePopUpAssign={handlePopUpAssign}
              handleAssign={handleAssign}
            />
          ) : (
            <></>
          )}
          <div style={styles.chatContainer}>
            <div style={styles.chatMessages}>
              {playgroundTrainingState.conversation &&
              playgroundTrainingState.conversation.slice(1).length ? (
                <div style={styles.chatAll}>
                  <div style={styles.chatMessagesActions}>
                    <div style={styles.userInformation}>
                      <div style={styles.chatChannelSingle}>
                        <div style={styles.iconAnalyticsDiv2}>
                          <svg
                            style={styles.iconAnalytics2}
                            fill="currentColor"
                            class="bi bi-person-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                        </div>
                      </div>
                      <span style={styles.nameUser}>
                        {loginUser.firstName} {loginUser.lastName}
                      </span>
                    </div>
                    <div style={styles.buttonActionsGroup}>
                      <button
                        style={styles.buttonActions}
                        onClick={() => handlePopUpFeatures()}
                      >
                        <svg
                          style={styles.iconActions}
                          fill="currentColor"
                          class="bi bi-send"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                        <span style={styles.textActions}>
                          {language === "english" ? "Share" : "Compartir"}
                        </span>
                      </button>
                      <button
                        style={styles.buttonActions}
                        onClick={() => handlePopUpFeatures()}
                      >
                        <svg
                          style={styles.iconActions}
                          fill="currentColor"
                          class="bi bi-bookmark-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                          <path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4z" />
                        </svg>
                        <span style={styles.textActions}>
                          {language === "english" ? "Save" : "Guardar"}
                        </span>
                      </button>
                      <button
                        style={styles.buttonActions}
                        type="button"
                        onClick={(e) => handleReset()}
                      >
                        <svg
                          style={styles.iconActions}
                          fill="currentColor"
                          class="bi bi-arrow-counterclockwise"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"
                          />
                          <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466" />
                        </svg>
                        <span style={styles.textActions}>
                          {language === "english" ? "Restart" : "Reiniciar"}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div style={styles.singleMessages}>
                    {playgroundTrainingState.conversation.slice(1).length ? (
                      <>
                        {playgroundTrainingState.conversation
                          .slice(1)
                          .map((singleMessage, i) => {
                            const role = singleMessage.role;
                    
                            const switchStyleMessage = {
                              user: i ? styles.chatSent : { ...styles.chatSent, marginTop: 0 },
                              assistant: styles.chatReceived,
                            };


                            return (
                              <div id={"chatSingleMessage"} key={i} style={switchStyleMessage[role]}>
                                {singleMessage.message}
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <div style={styles.chatNoMessagesAll}>
                  {!playgroundTrainingState.isLoading ? (
                    <>
                      <img
                        src="https://i.postimg.cc/SKKBxj69/enamorado.png"
                        height="150"
                        alt="Eric from Inside One"
                      />

                      <div style={{ display: "flex", gap: 10 }}>
                        <button
                          onClick={handleInitTest}
                          style={styles.buttonTest}
                        >
                          {language === "english"
                            ? "Start test"
                            : "Iniciar prueba"}
                        </button>
                        {(isOurCompanyName && !testRealAgentState) ? (
                          <button
                            onClick={handleInitTestRealAgent}
                            style={styles.buttonTest}
                          >
                            {language === "english"
                              ? "Start real agent test"
                              : "Prueba de Agente Real"}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      style={styles.custom}
                      className="spinner-border"
                      role="status"
                    />
                  )}
                </div>
              )}
              <form style={styles.textBoxChat} onSubmit={handleSubmit}>
                <input
                  name="textChat"
                  type="text"
                  // rows="1"
                  className="form-control"
                  placeholder={
                    language === "english" ? "Write here..." : "Escribe aquí..."
                  }
                  style={styles.inputChat}
                  value={messageToSend}
                  onChange={handleChangeText}
                  disabled={
                    playgroundTrainingState.isLoading ||
                    playgroundTrainingState.conversation?.length > 2
                  }
                />
                <button
                  type="submit"
                  style={styles.button}
                  onClick={handleSubmit}
                  disabled={
                    playgroundTrainingState.isLoading ||
                    playgroundTrainingState.conversation?.length > 2
                  }
                >
                  {language === "english" ? "Send" : "Enviar"}
                </button>
              </form>
            </div>
            {
              playgroundTrainingState.analyze && !playgroundTrainingState.isLoading  ? (
                <div style={styles.chatSessions}>
                  <div
                    style={{
                      ...styles.chatSessionPadding3,
                      maxHeight: "150px",
                    }}
                  >
                    <div
                      className="btn btn-primary"
                      style={styles.chatSession3}
                    >
                      <div style={styles.chatChannel}>
                        <div style={styles.iconAnalyticsDiv}>
                          <svg
                            style={styles.iconAnalytics}
                            fill="currentColor"
                            className="bi bi-pie-chart"
                            viewBox="0 0 16 16"
                          >
                            <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8" />
                          </svg>
                        </div>
                      </div>
                      <div style={styles.chatInformation}>
                        <h6 style={styles.chatSessionName}>
                          {language === "english"
                            ? "Calification"
                            : "Calificación"}
                        </h6>
                        <div style={styles.chatSessionTextDiv}>
                          <h6
                            style={{ ...styles.chatSessionText, fontSize: 40 }}
                          >
                            {playgroundTrainingState.analyze.calification !==
                            "--"
                              ? playgroundTrainingState.analyze.calification +
                                "%"
                              : "--"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      ...styles.chatSessionPadding3,
                      maxHeight: "150px",
                    }}
                  >
                    <div
                      className="btn btn-primary"
                      style={styles.chatSession3}
                    >
                      <div style={styles.chatChannel}>
                        <div style={styles.iconAnalyticsDiv}>
                          <svg
                            style={styles.iconAnalytics}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="bi bi-wrench-adjustable-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.496 8a4.5 4.5 0 0 1-1.703 3.526L9.497 8.5l2.959-1.11q.04.3.04.61" />
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-1 0a7 7 0 1 0-13.202 3.249l1.988-1.657a4.5 4.5 0 0 1 7.537-4.623L7.497 6.5l1 2.5 1.333 3.11c-.56.251-1.18.39-1.833.39a4.5 4.5 0 0 1-1.592-.29L4.747 14.2A7 7 0 0 0 15 8m-8.295.139a.25.25 0 0 0-.288-.376l-1.5.5.159.474.808-.27-.595.894a.25.25 0 0 0 .287.376l.808-.27-.595.894a.25.25 0 0 0 .287.376l1.5-.5-.159-.474-.808.27.596-.894a.25.25 0 0 0-.288-.376l-.808.27z" />
                          </svg>
                        </div>
                      </div>
                      <div style={styles.chatInformation}>
                        <h6 style={styles.chatSessionName}>
                          {language === "english"
                            ? "Technicality"
                            : "Tecnicismo"}
                        </h6>
                        <div style={styles.chatSessionTextDiv}>
                          <h6
                            style={{ ...styles.chatSessionText, fontSize: 40 }}
                          >
                            {playgroundTrainingState.analyze?.technicality !==
                            "--"
                              ? playgroundTrainingState.analyze?.technicality +
                                "%"
                              : "--"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={styles.chatSessionPadding3}>
                    <div
                      className="btn btn-primary"
                      style={styles.chatSession3}
                    >
                      <div style={styles.chatChannel}>
                        <div style={styles.iconAnalyticsDiv}>
                          <svg
                            style={styles.iconAnalytics}
                            fill="currentColor"
                            className="bi bi-bar-chart"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
                          </svg>
                        </div>
                      </div>
                      <div style={styles.chatInformation}>
                        <h6 style={styles.chatSessionName}>
                          {language === "english"
                            ? "Recomendation"
                            : "Recomendación"}
                        </h6>
                        <div style={styles.chatSessionTextDiv}>
                          <h6 style={styles.chatSessionText}>
                            {playgroundTrainingState.analyze.recommendation}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : playgroundTrainingState.isLoading ? (
                <div style={styles.chatNoSessions}>
                  <div style={styles.noChats}>
                    {language === "english" ? "Loading..." : "Cargando... "}
                  </div>
                </div>
              ) : (
                <div style={styles.chatNoSessions}>
                  <div style={styles.noChats}>
                    {language === "english"
                      ? "No analysis"
                      : "Sin análisis realizado"}
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};
