import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assistantsID: null,
  filesOpenAI: null,
  manualKnowledge: null,
  isLoading: false
};
export const infoAssistant = createSlice({
  name: "infoAssistant",
  initialState,
  reducers: {
    setFilesOpenAI: (state, { payload }) => {
      state.filesOpenAI = payload;
    },
    setManualKnowledge: (state, { payload }) => {
      state.manualKnowledge = payload?.reverse();
    },
    setIsLoading: (state, {payload}) =>{
      state.isLoading = payload
    }
  },
});

export const { setFilesOpenAI , setManualKnowledge, setIsLoading } = infoAssistant.actions;
