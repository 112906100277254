import React, { useEffect, useState } from "react";
import axios from "axios";
//redux
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import { setTestRealAgent } from "../store/reducers/slice/settings/settingsSlice";
//components
import Settings from "../components/Settings";
import Sidebar from "./Sidebar";
import {
  pushToQualification,
  pushToRecommendations,
  removeRecommendation,
  setDropdownQualification,
  setDropdownRecommendations,
  setTechnicalityLevel,
  removeQualification,
} from "../store/reducers/slice/fineTuning/fineTuning";

import { compareTwoArrays } from "../utils/compareTwoArrays";

const SettingsClient = () => {
  const screenSize = useSelector((state) => state.screenSizeReducer.size);
  const fineTuningState = useSelector((state) => state.fineTuning);

  const dispatch = useDispatch();
  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);
  const testRealAgentState = useSelector((state) => state.settingsReducer.testRealAgent);
  const { name: companyName,  } = useSelector((state) => state.user.companyInfo);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
    const loginUser = useSelector(
      (state) => state.user.loginUser
    );

  const handleDarkMode = () => {
    if (darkTheme === false) {
      dispatch(loginUserPreferences("darkTheme", true));
    } else {
      dispatch(loginUserPreferences("darkTheme", false));
    }
  };

  const handleLanguage = () => {
    if (language === "english") {
      dispatch(loginUserPreferences("language", "spanish"));
    } else {
      dispatch(loginUserPreferences("language", "english"));
    }
  };

  const handleRealTest = () => {
    dispatch(setTestRealAgent(!testRealAgentState));
  };
  //! FINE TUNIING
  //*compare original data

  const [dataChange, setDataChange] = useState(false);
  const isOriginalDataChange = () => {
    const originalData = fineTuningState.originalData;
    /*     if (originalData) {
      const currentRecommendations = fineTuningState.recommendations;
      const currentQualification = fineTuningState.qualifications;
      const currentTechnicalityLevel = fineTuningState.technicalityLevel;
      const isTechnicalityLevelChange =
        originalData?.technicalityLevel === currentTechnicalityLevel;

        console.log("currentQualification",currentQualification);
        console.log("originalData?.qualification",originalData?.qualification);
      //setDataChange(true)

      const verdadero =
        compareTwoArrays(originalData?.recommendations, currentRecommendations) &&
        compareTwoArrays(originalData?.qualification, currentQualification) &&
        isTechnicalityLevelChange;
        console.log(verdadero)
        if(verdadero){
          setDataChange(false)
        }else{
          setDataChange(true)
        }
    }  */
    //return;
  };

  //Handle select
  /**
   * Handles the selection of the technicality level.
   */
  const hanldeSelectTechnicalityLevel = () => {
    //Get current technicality level
    const currentTechnicalityLevel = fineTuningState.technicalityLevel;
    //Array of technicality options
    const arrayTechnicalityOptions = ["formal", "informal"];
    //Get index of current technicality level
    const index = arrayTechnicalityOptions.indexOf(currentTechnicalityLevel);
    const nextIndex = index + 1;
    //Check if is the last index
    const isLastIndex = nextIndex === arrayTechnicalityOptions.length;
    //Get next technicality level
    const nextTechnicality = isLastIndex
      ? arrayTechnicalityOptions[0]
      : arrayTechnicalityOptions[nextIndex];
    dispatch(setTechnicalityLevel(nextTechnicality));

    const originalData = fineTuningState.originalData;
    if (originalData?.technicalityLevel === nextTechnicality) {
      setDataChange(false);
    } else if (
      originalData?.technicalityLevel !== undefined &&
      originalData?.technicalityLevel !== nextTechnicality
    ) {
      setDataChange(true);
    }
  };

  //Handle submit
  const handleSubmitRecommendations = (e) => {
    e.preventDefault();
    dispatch(pushToRecommendations(e.target[0].value));
    e.target[0].value = "";
  };
  //este useEffect es para comparar los arrays de redux con el nuevos arrays que se cargan(recommendations y quelifications) tambien se compara el technicality level. EN caso de que sean iguales, el state del boton para guardar (Save )no aparece. Si alguna condicion es diferente , entonces el boton si aparece.
  useEffect(() => {
    const originalData = fineTuningState?.originalData;
    const isTechnicalityLevelChange = originalData?.technicalityLevel === fineTuningState.technicalityLevel;
    if (
      Array.isArray(originalData?.recommendations) &&
      Array.isArray(originalData?.qualification) &&
      fineTuningState.technicalityLevel
    ) {
      if (
        compareTwoArrays(originalData?.recommendations, fineTuningState.recommendations) &&
        compareTwoArrays(originalData?.qualification, fineTuningState.qualifications) &&
        isTechnicalityLevelChange
      ) {
        setDataChange(false);
      } else {
        setDataChange(true);
      }
    }
  }, [
    fineTuningState.recommendations,
    fineTuningState.originalData,
    fineTuningState.qualifications,
    fineTuningState.technicalityLevel,
  ]);

  const handleSubmitQualification = (e) => {
    //e.preventDefault();
    dispatch(pushToQualification(e.target[0].value));
    console.log(e.target[0].value);
    e.target[0].value = "";
  };
  //Handle dropdown
  const handleDropdownRecommendations = () =>
    dispatch(setDropdownRecommendations(!fineTuningState.dropdownRecommendations));
  const handleDropdownQualification = () =>
    dispatch(setDropdownQualification(!fineTuningState.dropdownQualifications));

  //!Removes
  const handleRemoveRecommendation = (e) => dispatch(removeRecommendation(e));
  const handleRemoveQualification = (e) => dispatch(removeQualification(e));

  //SEND
  const handleSendFineTuning = async () => {
    const endpoint = "https://updatefinetunning-cfcs6xjhea-uc.a.run.app";
    const body = {
      calification: fineTuningState.qualifications,
      technicalityLevel: fineTuningState.technicalityLevel,
      recommendation: fineTuningState.recommendations,
    };
    setIsLoadingSave(true);
    try {
      await axios.post(`${endpoint}/?instanceName=${companyName}`, body);
      isOriginalDataChange();
    } catch (error) {
      console.error(error);
    }
    setIsLoadingSave(false);
  };
  return (
    <div>
      <Sidebar />
      <Settings
        //STATES
        fineTuningState={fineTuningState}
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        testRealAgentState={testRealAgentState}
        isLoadingSave={isLoadingSave}
        dataChange={dataChange}
        //Handlers
        handleDarkMode={handleDarkMode}
        handleLanguage={handleLanguage}
        handleRealTest={handleRealTest}
        handleDropdownRecommendations={handleDropdownRecommendations}
        handleSubmitRecommendations={handleSubmitRecommendations}
        handleRemoveRecommendation={handleRemoveRecommendation}
        hanldeSelectTechnicalityLevel={hanldeSelectTechnicalityLevel}
        handleSubmitQualification={handleSubmitQualification}
        handleDropdownQualification={handleDropdownQualification}
        handleRemoveQualification={handleRemoveQualification}
        //Functions
        isOriginalDataChange={isOriginalDataChange}
        handleSendFineTuning={handleSendFineTuning}
        setDataChange={setDataChange}
        loginUser={loginUser}
      />
    </div>
  );
};

export default SettingsClient;
