import React, { useState, useEffect } from "react";
import crossIcon from "../assets/icons/cross.svg";
import Sidebar from "../containers/Sidebar";
import ErickApuntando from "../assets/images/erick-apunta.png";
import { useSelector } from "react-redux";
import firebase from "../config/firebaseIO";
import { getStorage, ref } from "firebase/storage";
import { useHistory } from "react-router-dom";
import notAvailable from "../assets/images/No_Image_Available.jpg";

const videos = [
  { url: "9L1tkmj8VSc", title: "¿Qué es un seguro?" },
  { url: "KqXYQxwQ_Oo", title: "Cómo funcionan los seguros" },
  { url: "W-MwmfLFlM0", title: "Todo lo que tenes que saber sobre seguros" },
  { url: "CRfQhOLEzmI", title: "Tipos de seguros" },
  { url: "983S6oLVUvY", title: "Cómo empezar a vender seguros" },
  { url: "psktThtioXk", title: "Seguro de Vida" },
  { url: "lKVzSit0eIs", title: "Seguros generales" },
  { url: "9Pu7c0Feoh8", title: "Funcionamiento de los seguros" },
  { url: "USQnnCoZO8Y", title: "Experto de seguros" },
  { url: "n-PzLnV08Bg", title: "Seguro de hogar" },
];

export default function Knowledge() {
  const language = useSelector((state) => state.user.language);
  const darkModeState = useSelector((state) => state.user.darkTheme);
  const screenSize = useSelector((state) => state.screenSizeReducer.size);
  const { files_openai } = useSelector((state) => state.docsGaleryReducer);

  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [infoArchivos, setInfoArchivos] = useState([]);

  const styles = {
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      padding: "3% 2% 3% 280px",
      height: "100%",
      overflow: `auto`,
    },
    navbar: {
      display: "flex",
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
    },
    textsNavbar: {
      display: "flex",
      flexDirection: "column",
    },
    titleNavbar: {
      textAlign: "left",
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    knowledge: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      height: 140,
      minHeight: 140,
      borderRadius: 5,
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      position: "relative",
    },
    buttonUpload: {
      backgroundColor: "#4dc2f1",
      fontSize: "16px",
      fontFamily: "InterRegular",
      borderRadius: "5px",
      color: "#fff",
      padding: "5px, 5px",
      border: "0px",
      cursor: "pointer",
      height: "40px",
    },
    modalOverlay: {
      position: "fixed",
      zIndex: "2000",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      overflowY: "auto",
    },
    modalContainer: {
      width: "70%",
      height: "85%",
      marginTop: "2%",
      marginLeft: "15%",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      borderRadius: 5,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalButtonCloseModal: {
      position: "absolute",
      right: 5,
      top: 5,
      border: "none",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "white"}`,
      cursor: "pointer",
    },
    pYButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      paddingRight: "20px",
      alignItems: "center",
    },
  };

  useEffect(() => {
    const filesFilteredToShow = files_openai?.filter((file) => file.show_file)
  

    setInfoArchivos(filesFilteredToShow);
  }, [files_openai]);

  const openModal = (video) => {
    setSelectedVideo(video);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setModalVisible(false);
  };



  return (
    <>
      <Sidebar />
      <div style={styles.backColor}>
        <div style={styles.container}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>
              {language === "english" ? "Training" : "Capacitación"} 🤓
            </h3>
            <h3 style={styles.subtitleNavbar}>
              {language === "english"
                ? "All the material you need to learn about your new position."
                : "Todo el material necesario para aprender sobre tu nuevo puesto."}
            </h3>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 25,
              marginTop: 25,
            }}
          >
            {infoArchivos.reverse().map(({ file_name, file_path }, index) => {
              return (
                <div style={styles.knowledge} key={index + '_' + file_name }>
                  <img
                    src={notAvailable}
                    alt={`Video Thumbnail ${index}`}
                    width="120"
                    height="100"
                    style={{
                      borderRadius: "5px",
                      marginRight: "40px",
                      marginLeft: "20px",
                    }}
                  />
                  <div style={styles.pYButton}>
                    <p style={{ paddingTop: "20px", marginLeft: "80px" }}>
                      {" "}
                      {file_name}
                    </p>

                    <a href={file_path} target="_blank">
                      <button
                        //onClick={()=> history.push(archivo.url)}
                        style={{
                          ...styles.buttonUpload,
                          width: "100px",
                          minHeight: "40px",
                          height: "content-fit",
                        }}
                      >
                        {language === "english"
                          ? file_name.endsWith(".pdf")
                            ? "Read file"
                            : file_name.endsWith(".mp4")
                            ? "Watch video"
                            : file_name.endsWith(".mp3")
                            ? "Listen mp3"
                            : file_name.endsWith(".txt")
                            ? "See txt"
                            : null
                          : language === "spanish"
                          ? file_name.endsWith(".pdf")
                            ? "Ver pdf"
                            : file_name.endsWith(".mp4")
                            ? "Ver video"
                            : file_name.endsWith(".mp3")
                            ? "Escuchar mp3"
                            : file_name.endsWith(".txt")
                            ? "Ver txt"
                            : null
                          : null}
                      </button>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 25,
              marginTop: 25,
            }}
          >
            {videos.map((video, index) => {
              return (
                <div style={styles.knowledge}>
                  <img
                    src={`https://img.youtube.com/vi/${video.url}/default.jpg`}
                    alt={`Video Thumbnail ${index}`}
                    width="120"
                    height="100"
                    style={{
                      borderRadius: "5px",
                      marginRight: "40px",
                      marginLeft: "20px",
                    }}
                  />
                  <div style={styles.pYButton}>
                    <p style={{ paddingTop: "20px", marginLeft: "80px" }}>
                      {video.title}
                    </p>
                    <button
                      style={{
                        ...styles.buttonUpload,
                        width: "100px",
                        minHeight: "40px",
                        height: "content-fit",
                      }}
                      onClick={() => openModal(video)}
                    >
                      {language === "english" ? "Watch video" : "Ver video"}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {modalVisible && selectedVideo && (
          <div style={styles.modalOverlay} onClick={closeModal}>
            <div style={styles.modalContainer}>
              <button style={styles.modalButtonCloseModal} onClick={closeModal}>
                <img src={crossIcon} alt="Close Modal" width="20" height="20" />
              </button>
              <iframe
                width="990"
                height="520"
                src={`https://www.youtube.com/embed/${selectedVideo.url}`}
                title={selectedVideo.title}
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <img
                src={ErickApuntando}
                alt="Erick"
                style={{ position: "absolute", bottom: 0, right: 0 }}
                height="15%"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
