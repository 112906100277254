import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversation: [],
  threadID: '',
  loadingConversation: false,
  history: [],
  loadingDelete:false,
};
export const questionsTraining = createSlice({
  name: "questionsTraining",

  initialState,
  reducers: {
    setQuestionsConversation: (state, { payload }) => {
      state.conversation = payload.conversation
      state.threadID = payload.threadID;
      state.loadingConversation = false;
    },
    setLoadingQuestionsConversation: (state, { payload }) => {
      state.loadingConversation = payload;
    },
    resetQuestionsTraining: (state) => {
      state.conversation = [];
      state.threadID = '';
    },
    pushToQuestionsConversation: ( state , {payload} ) => {
      state.conversation.push({ role: 'user' , message: payload})
    },
    setQuestionsHistory: (state, { payload }) => {
      state.history = payload;
    },
    setLoadingDelete: (state, {payload})=>{
      state.loadingDelete = payload
    }
  },
});

export const { setLoadingDelete,setQuestionsHistory ,  setQuestionsConversation, setLoadingQuestionsConversation, resetQuestionsTraining , pushToQuestionsConversation } =
  questionsTraining.actions;
