import { createSlice } from "@reduxjs/toolkit";


export const singleView = createSlice({
  name: "singleView",
  initialState: {
    modalState: false,
    selectedReport: null
  },
  reducers: {
    setModal: (state , { payload }) => {
      const selectedReport = payload.selectedReport
      const modalState = payload.modalState

      state.modalState = modalState
      state.selectedReport = selectedReport 
    }
  
  },
});

// Action creators are generated for each case reducer function
export const { setModal } = singleView.actions