import React, { useState, useEffect, useRef, useTransition } from "react";
import Sidebar from "./Sidebar";
import SingleEvaluations from "../components/SingleEvaluations";
// Router-DOM
import { useLocation, useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setPaginatedData,
  setFilter,
} from "../store/reducers/slice/firestore";
import { setModal } from "../store/reducers/slice/singleView";
//util





  //TODO:Nombre del agente
  //TODO:Cantidad de evaluaciones realizadas
  //TODO:Porcentaje total de evaluaciones
  //TODO:Ultima evaluacion realizada (fecha)
  //TODO: Indice de promedio
  
const SingleEvaluationsClient = () => {
  const screenSize = useSelector(state=>state.screenSizeReducer.size)
  const darkTheme = useSelector((state) => state.user.darkTheme);
  const language = useSelector((state) => state.user.language);
  const singleViewStates = useSelector((state) => state.singleViewReducer);




  const {
    data,
    currentPage,
    itemsPerPage,
    filter,
    agentsState,
    paginatedData,
    playgroundMode,
    isFetching
  } = useSelector((store) => store.firestoreReducer);

  // const [reports, setReports] = useState([]);
  // const [dateToday, setDateToday] = useState("");
  // const [arrayDeOpciones, setArrayDeOpciones] = useState([]);
  // const [opcionFilter, setEl2] = useState("");
  // const [filterOn, setFilterOn] = useState(false);
  // const [OldAnwers, setOldAnwers] = useState([]);
  // const [fecha1, setFecha1] = useState("");
  // const [fecha2, setFecha2] = useState("");
  // const [search, setSearch] = useState("");
  //estados para visualizar los filtros

  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [selectedReport, setSelectedReport] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const totalPages = Math.ceil( filter ? filter?.length / itemsPerPage : data?.length / itemsPerPage);
  const { selectedReport , modalState } = useSelector((state) => state.singleViewReducer);



  const totalPagesArr = [];
  for (let i = 1; i <= totalPages; i++) {
    totalPagesArr.push({ number: i, isCurrent: i === currentPage });
  }

  const handleDownloadCSV = () => {
    console.log("click");
  };

  const handleOpenModal = (report) => {
    dispatch(setModal({ modalState: true, selectedReport: report }))
  };

  const handleCloseModal = () => {
    dispatch(setModal({ modalState: false, selectedReport: null }))
  };

  const handleClick = () => {
    history.push("/playground");
  };


    // dispatch(setModal({ modalState: true, selectedReport: paginatedData ? paginatedData[0] : null }))

  


  useEffect(() => {
    const fetchData = async () => {
  
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const dataToPaginate = filter
          ? filter?.slice(startIndex, endIndex)
          : data?.slice(startIndex, endIndex);
  
      try {
        const paginatedData = dataToPaginate;
        dispatch(setPaginatedData(paginatedData));
      
      } catch (error) {
        console.error("Error al traer la data:", error);
      }
    };

    fetchData();
  }, [currentPage, data, itemsPerPage, filter, language]);

  useEffect(() => {
    dispatch(setCurrentPage(1));
  }, []);


  

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
      window.scroll(0, 0);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      dispatch(setCurrentPage(currentPage + 1));
      window.scroll(0, 0);
    }
  };


  return (
    <>
      <Sidebar />
      <SingleEvaluations
        
        history={handleClick}
        handleDownloadCSV={handleDownloadCSV}
        language={language}
        modalState={singleViewStates.modalState}
        paginatedData={paginatedData}
        screenSize={screenSize}
        darkModeState={darkTheme}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        currentPage={currentPage}
        totalPages={totalPages}
        location={location}
        totalPagesArr={totalPagesArr}
        isFetching={isFetching}
       
      />
    </>
  );
};

export default SingleEvaluationsClient;
