import React from "react";

export default function RecomendationIcon({ styles }) {
  // const styles = {
  //   marginRight: 7,
  //   maxWidth: 26,
  //   maxHeight: 30,
  //   minWidth: 26,
  //   minHeight: 30,
  //   color: "rgb(139, 151, 198)",
  // };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      style={styles}
    >
      <path d="M20 3H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14H5v-2h6v2zm8-4H5v-2h14v2zm0-4H5V7h14v2z"></path>
    </svg>
  );
}
