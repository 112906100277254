import React from "react";

import { Bar, Pie, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  PointElement,
  BarElement,
  ArcElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

export default ({
  screenSize,
  darkModeState,
  language,
  messages,
  displayCharts,
  chartsData,
  totalizatorData,
  updatedTitle,
  playgroundMode,
  isFetching,
  dataTopAgents,
}) => {
  /*  const dataPrueba = data.map((ele) => ele.client.rating); */

  ChartJS.register(
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    PointElement,
    BarElement,
    PointElement,
    ArcElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
  );

  const styles = {
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
      padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
      height: "100%",
      overflow: `auto`,
    },
    containerLoader: {
      display: "flex",
      alignItem: "center",
      justifyContent: "center",
      height: "80%",
    },
    navbar: {
      display: "flex",
      flex: 1.5,
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
    },
    textsNavbar: {
      display: "flex",
      flex: 8,
      flexDirection: "column",
    },
    titleNavbar: {
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    reports: {
      display: "flex",
      flex: 11,
      flexDirection: "column",
      margin: `${screenSize > 800 ? "0px 0px" : "0px"}`,
      padding: "0px 0px 20px 0px",
      borderRadius: "10px",
      gap: "10px",
    },
    reports2: {
      display: "flex",
      flexDirection: "row",
      margin: `${screenSize > 800 ? "0px 0px" : "0px"}`,
      overflow: `auto`,
      borderRadius: "10px",
      marginBottom: "20px",
    },
    singleLineReport: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    singleBarCharts: {
      flex: 1,
      alignItems: "flex-start",
      justifyContent: "flex-start",
      borderRadius: "10px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      width: "100px",
    },
    invisible: {
      display: "none",
    },
    textToogle: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: "12px",
      backgroundColor: `transparent`,
    },
    textUser: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: 0,
      backgroundColor: `transparent`,
    },
    campaigns: {
      display: "flex",
      flex: 11,
      flexDirection: "column",
      margin: `${screenSize > 800 ? "10px 20px" : "0px"}`,
    },
    generalIndicators: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: `${screenSize > 800 ? "no-wrap" : "wrap"}`,
      margin: `${screenSize > 800 ? "0px" : "0px 0px 30px"}`,
      width: "100%",
    },
    generalIndicators2: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: `${screenSize > 800 ? "no-wrap" : "wrap"}`,
      margin: `${screenSize > 800 ? "0px 0px 30px 0px" : "0px 0px 30px"}`,
      width: "100%",
    },
    singleDetIndicator: {
      display: "flex",
      width: `${screenSize > 800 ? "49.5%" : "80vw"}`,
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      // border: '2px solid red',
      //  margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
      margin: `${screenSize > 800 ? "10px 0px" : "10px"}`,
    },
    singleDetIndicator2: {
      display: "flex",
      width: `${screenSize > 800 ? "49.5%" : "80vw"}`,
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      //  margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
      margin: `${screenSize > 800 ? "0px 0px" : "10px"}`,
    },
    contentSingleIndicatorLineTop: {
      display: "flex",
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "space-between",
      marginBottom: `12px`,
    },
    contentSingleIndicatorLineDown: {
      display: "flex",
      alignItems: "space-between",
      justifyContent: "space-between",
    },
    textDetailCard: {
      display: "flex",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      //  color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
      margin: 0,
      fontSize: "14px",
      fontFamily: "InterBold",
    },
    chartsText: {
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,

      fontFamily: "InterBold",
    },
    chartsTextColor: {
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
    icons: {
      width: "20px",
      height: "20px",
      color: "#4dc2f1",
    },
    numberLittleCard: {
      display: "flex",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: 0,
      fontSize: "30px",
      fontFamily: "InterBold",
    },
    buttonNew: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      textDecoration: "none",
      outline: 0,
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      borderRadius: 5,
      margin: 0,
      padding: "12px 32px",
    },
    singleCampaign: {
      display: "flex",
      //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    lineDetail: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    channelMessage: {
      display: "flex",
      flex: 3,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: "0px",
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "start",
      //  alignSelf: 'center',
      alignItems: "center",
      //    justifyContent: 'center'
    },
    nameCampaign: {
      display: "flex",
      flex: 3,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: "0px",
      fontSize: "16px",
      fontFamily: "InterBold",
      textAlign: "start",
      alignSelf: "center",
      alignItems: "center",
    },
    detailChannel: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: "0px",
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "start",
      alignSelf: "center",
      alignItems: "center",
    },
    agentMessage: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: "0px",
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "start",
      alignSelf: "center",
      alignItems: "center",
    },
    messsageChannel: {
      display: "flex",
      flex: 8,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: "0px",
      fontSize: "14px",
      fontFamily: "InterRegular",
      textAlign: "start",
      alignSelf: "center",
      alignItems: "center",
      padding: "0px 60px 0px 0px",
    },
    typeCampaign: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "14px",
      fontFamily: "InterBold",
      // textAlign: 'end'
      alignSelf: "center",
      justifyContent: "flex-end",
    },
    loader: {
      display: "flex",
      alignSelf: "center",
    },
    imageChannel: {
      height: 40,
      margin: "0px 20px 0px 0px",
    },
    singleCounter: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "10px 25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "2.4% 0px -10px" : "10px"}`,
      backgroundColor: "#4dc2f1",
      color: "#fff",
    },
    titleCounter: {
      display: "flex",
      flex: 4,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: "0px",
      fontSize: "18px",
      fontFamily: "InterBold",
      textAlign: "start",
      alignSelf: "center",
      color: "#fff",
    },
    numberCounter: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "end",
      alignSelf: "center",
      justifyContent: "flex-end",
      color: "#fff",
    },
    downloadsContainer: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      borderRadius: "5px",
      padding: "10px 0px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px 0px" : "10px"}`,
      backgroundColor: "transparent",
    },
    downloadsButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      outline: 0,
      color: "#4dc2f1",
      backgroundColor: "transparent",
      border: "1px #4dc2f1 solid",
      borderRadius: 5,
      margin: "0px 0px 0px 10px",
      padding: "8px 13px",
      width: "180px",
    },
  };

  //array que guardara los datos a exportar y muestra la longitud de los datos mostrados en pantalla

  //funcion para descargar en formato cvs

  return (
    <>
      <div style={styles.backColor} />
      <div style={styles.container}>
        <div style={styles.navbar}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>
              {language === "english" ? "Metrics" : "Métricas"} 😃
            </h3>
            <h3 style={styles.subtitleNavbar}>
              {language === "english"
                ? "Statistical data on agent training."
                : "Datos estadísticos sobre el entrenamiento de los agentes."}
            </h3>
          </div>
        </div>

        <div style={styles.campaigns}>
          <div style={{ display: "flex", flexWrap: "no-wrap", gap: 5 }}>
            {/* INTERACCIONES DE HOY */}
            <div
              className={darkModeState ? "buttonDark" : "buttonLight"}
              style={styles.singleDetIndicator}
            >
              <div style={styles.contentSingleIndicatorLineTop}>
                <div style={styles.contentSingleIndicatorLineDown}>
                  <p style={styles.textDetailCard}>
                    {totalizatorData[0].title}
                  </p>

                  <p style={styles.textDetailCard}>
                    {totalizatorData[0].subtitle}
                  </p>
                </div>

                <p style={styles.numberLittleCard}>
                  {totalizatorData[0].quantity.todayInteractions
                    ? totalizatorData[0].quantity.todayInteractions
                    : "0"}
                </p>
              </div>
            </div>

            {/* INTERACCIONES DE LA SEMANA */}

            <div
              className={darkModeState ? "buttonDark" : "buttonLight"}
              style={styles.singleDetIndicator}
            >
              <div style={styles.contentSingleIndicatorLineTop}>
                <div style={styles.contentSingleIndicatorLineDown}>
                  <p style={styles.textDetailCard}>
                    {totalizatorData[1].title}
                  </p>

                  <p style={styles.textDetailCard}>
                    {totalizatorData[1].subtitle}
                  </p>
                </div>

                <p style={styles.numberLittleCard}>
                  {totalizatorData[1].quantity.weekInteractions
                    ? totalizatorData[1].quantity.weekInteractions
                    : "0"}
                </p>
              </div>
            </div>

            {/* PROMEDIO DE TIEMPO DE RESPUESTA */}
            <div
              className={darkModeState ? "buttonDark" : "buttonLight"}
              style={styles.singleDetIndicator}
            >
              <div style={styles.contentSingleIndicatorLineTop}>
                <div style={styles.contentSingleIndicatorLineDown}>
                  <p style={styles.textDetailCard}>
                    {totalizatorData[4].title}
                  </p>
                  <p style={styles.textDetailCard}>Total</p>
                </div>

                <p style={styles.numberLittleCard}>
                  {totalizatorData[4].quantity.totalTime
                    ? totalizatorData[4].quantity?.totalTime
                    : "0m 0s"}
                </p>
              </div>
            </div>
          </div>

          {/* tops */}

          <div
            style={{
              display: "flex",
              flexWrap: "no-wrap",
              justifyContent: "space-between",
            }}
          >
            {/* TOP MEJORES AGENTES */}
            <div
              className={darkModeState ? "buttonDark" : "buttonLight"}
              style={{ ...styles.singleDetIndicator, width: "49.6%" }}
            >
              <div
                style={{
                  ...styles.contentSingleIndicatorLineTop,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    ...styles.textDetailCard,
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  {dataTopAgents.bestAgents.title}
                </h2>

                <ol
                  style={{
                    width: "100%",
                    minHeight: 100,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {dataTopAgents.bestAgents.data?.map((agent, i) => {
                    return (
                      <li
                        key={i}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0 5px 0 5px",
                          width: "100%",
                          borderBottom: `1px solid ${
                            darkModeState
                              ? "hsl(228, 34%, 66%)"
                              : "hsl(228, 12%, 44%)"
                          }`,
                          marginBottom: 5,
                        }}
                      >
                        <span style={styles.textDetailCard}>
                          {i + 1}- {agent.name}
                        </span>

                        <span style={styles.textDetailCard}>
                          {" "}
                          {Math.floor(agent.averageCalification)}%
                        </span>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>

            {/* TOP PEORES AGENTES */}
            <div
              className={darkModeState ? "buttonDark" : "buttonLight"}
              style={{ ...styles.singleDetIndicator, width: "49.6%" }}
            >
              <div
                style={{
                  ...styles.contentSingleIndicatorLineTop,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    ...styles.textDetailCard,
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  {dataTopAgents.worstAgents.title}
                </h2>

                <ol
                  style={{
                    width: "100%",
                    minHeight: 100,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {dataTopAgents.worstAgents.data?.map((agent, i) => {
                    return (
                      <li
                        key={i}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0 5px 0 5px",
                          width: "100%",
                          borderBottom: `1px solid ${
                            darkModeState
                              ? "hsl(228, 34%, 66%)"
                              : "hsl(228, 12%, 44%)"
                          }`,
                          marginBottom: 5,
                        }}
                      >
                        <span style={styles.textDetailCard}>
                          {i + 1}- {agent.name}
                        </span>

                        <span style={styles.textDetailCard}>
                          {" "}
                          {Math.floor(agent.averageCalification)}%
                        </span>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          </div>
          {/* Barras */}

          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <div style={{ ...styles.reports2, width: "50%" }}>
              <div
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={{ ...styles.singleBarCharts, width: "100%" }}
              >
                <Bar
                  data={chartsData?.interactionsChart}
                  options={updatedTitle(
                    `${
                      language === "english"
                        ? "Weekly Evaluations "
                        : "Evaluaciones Semanales"
                    }`,
                    `${
                      language === "english"
                        ? "Monday through Friday"
                        : "Lunes a Viernes"
                    }`,
                    true,
                    false,
                    darkModeState
                  )}
                />
              </div>
            </div>

            <div style={{ ...styles.reports2, width: "50%" }}>
              <div
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={{ ...styles.singleBarCharts, width: "100%" }}
              >
                <Bar
                  data={chartsData?.weeklyAvarageRatingData}
                  options={updatedTitle(
                    `${
                      language === "english"
                        ? "Weekly Average Rating "
                        : "Calificación Semanal Promedio"
                    }`,
                    `${
                      language === "english"
                        ? "Monday through Friday"
                        : "Lunes a Viernes"
                    }`,
                    true,
                    false,
                    darkModeState
                  )}
                />
              </div>
            </div>
          </div>

          <div style={styles.reports}>
            <div
              style={
                displayCharts === false
                  ? styles.singleLineReport
                  : styles.invisible
              }
            >
              <div
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={styles.singleBarCharts}
              >
                <Doughnut
                  options={updatedTitle(
                    `${
                      language === "english"
                        ? "Score Index total"
                        : "Índice de Calificación total"
                    }`,
                    `${
                      language === "english"
                        ? "High - Medium - Low"
                        : "Alto - Medio - Bajo"
                    }`,
                    false,
                    true,
                    darkModeState
                  )}
                  data={chartsData?.indexDataCalificationChart}
                />{" "}
              </div>

              <div
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={styles.singleBarCharts}
              >
                <Pie
                  options={updatedTitle(
                    `${
                      language === "english"
                        ? "Response Time total"
                        : "Tiempo de respuesta total"
                    }`,
                    `${
                      language === "english"
                        ? "High - Medium - Low"
                        : "Alto - Medio - Bajo"
                    }`,
                    false,
                    true,
                    darkModeState
                  )}
                  data={chartsData?.indexTimeDataChart}
                />
              </div>

              <div
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={styles.singleBarCharts}
              >
                <Doughnut
                  options={updatedTitle(
                    `${
                      language === "english"
                        ? "Technical Index total"
                        : "Índice de tecnicidad total"
                    }`,
                    false,
                    false,
                    true,
                    darkModeState
                  )}
                  data={chartsData?.indexTechnicality}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
