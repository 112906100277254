import React from "react";

import RowTable from "./RowTable";
import { useLocation } from "react-router";
import "./tables.css";

export default ({ openModal, paginatedData, darkModeState, language, screenSize }) => {
  const location = useLocation();

  const styles = {
    tableHeadContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      margin: `${screenSize > 800 ? "30px 20px 20px 20px" : "0px"}`,
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px 15px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(224, 48%, 91%)"}`,
      // width: 'fit-content',
      // overflowX: screenSize > 800 ? 'none' : 'auto'
      minWidth: "427px",
    },
    tableHead: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "black"}`,
      // color: "black",
      margin: 0,
      fontSize: screenSize <= 800 ? ".9rem" : "1rem",
      fontFamily: "InterBold",
      fontWeight: "Bold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent: `${location.pathname === "/conversations" ? "flex-start" : "center"}`,
      paddingRight: "15px",
    },
  };


  return (
    <div style={{ overflowX: "auto", width: `${screenSize > 800 ? "auto" : "100%"}` }}>
      {location.pathname === "/evaluations" ? (
        <div
          className={darkModeState ? "buttonDark" : "buttonLight"}
          style={styles.tableHeadContainer}
        >
          <div style={styles.tableHead}>{language === "english" ? "Date" : "Fecha y Hora"}</div>
          <div style={styles.tableHead}>{language === "english" ? "Rating" : "Calificación"}</div>
          <div style={styles.tableHead}>{language === "english" ? "Technicality" : "Tecnicismo"}</div>
          <div style={styles.tableHead}>{language === "english" ? "Response time" : "Tiempo de Respuesta"}</div>
          <div style={styles.tableHead}>{language === "english" ? "Agent" : "Agente"}</div>
        </div>
      ) : (
        <div
          className={darkModeState ? "buttonDark" : "buttonLight"}
          style={styles.tableHeadContainer}
        >
          <div style={styles.tableHead}>{language === "english" ? "Name" : "Nombre"}</div>
          <div style={styles.tableHead}>{language === "english" ? "Evaluations" : "Evaluaciones"}</div>
          <div style={styles.tableHead}>{language === "english" ? "Average Rating" : "Calificación Promedio"}</div>
          <div style={styles.tableHead}>{language === "english" ? "Average Technicality" : "Tecnicismo Promedio"}</div>
          <div style={styles.tableHead}>{language === "english" ? "Average Time" : "Tiempo Promedio"}</div> 
          <div style={styles.tableHead}>{language === "english" ? "Last Evaluation" : "Última Evaluación"}</div>  
        </div>
      )}

      <RowTable
        paginatedData={paginatedData}
        darkModeState={darkModeState}
        language={language}
        openModal={openModal}
        screenSize={screenSize}
      />
    </div>
  );
};
